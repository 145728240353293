import React, { useEffect, useState, useRef } from "react"
import styles from "./emailtemplate.module.scss"
import ShapeLogo from "../images/shape-logo-email.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TickSuccess from "../images/success_message_icon.svg"
import ContentEditable from "react-contenteditable"
import moment from "moment"

const EmailTemplate = ({ location }) => {
  const emailref = useRef(null)
  // const location = useLocation()
  const params = new URLSearchParams(location.search)
  const name = params.get("name")
  const deadLine = params.get("deadline")
  const id = params.get("id")

  const [emailText, setEmailText] = useState("")
  const [textCopied, setTextCopied] = useState(false)
  const { templates } = require("../data/contact/emailTemplates.json")
  const emptyNamePlaceholder = "none"

  useEffect(() => {
    if (id && templates[id] && name)
      setEmailText(
        templates[id]
          .replace(
            `[name]`,
            name.toLowerCase() === emptyNamePlaceholder ? "" : name
          )
          .replace(
            "[deadline]",
            `${moment
              .parseZone(deadLine)
              .local()
              .format("HH:mm")} ${moment(deadLine).format(
              "dddd, MMMM Do, YYYY"
            )}`
          )
      )
  }, [id])

  return (
    <Layout>
      <SEO title="EmailTemplate" />

      <div className={styles.mainEmailTemplate}>
        <div className={styles.containerEmailTemplate}>
          <img
            src={ShapeLogo}
            className={styles.logoEmail}
            alt="SHAPE Logo Email"
          />
          <h2>Edit and copy the email template below</h2>
          <ContentEditable
            id={"email-text"}
            ref={emailref}
            className={styles.textarea}
            contentEditable="true"
            onChange={e => {
              setEmailText(e.target.value)
              setTextCopied(false)
            }}
            html={emailText}
            disabled={false}
          />
          {textCopied && (
            <div className={styles.copytext}>
              <img src={TickSuccess} alt="Success" />
              <span>Email copied to your clipboard </span>
            </div>
          )}
          <button
            onClick={() => {
              const emailSelectopnArea = emailref.current["el"]["current"]
              window.getSelection().selectAllChildren(emailSelectopnArea)
              document.execCommand("copy")
              setTextCopied(true)
            }}
          >
            Copy email template
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default EmailTemplate
